import { colors } from "@material-ui/core";
import tinycolor from "tinycolor2";

const primary = "#634099";
const secondary = "#FFC107";
const success = "#00C695";
const danger = "#FF3850";
const white = "#FFFFFF";
const black = "#000";
const athensGray = "#f4f6f8";
const kashmirBlue = "#546e8e";

const lightenRate = 7.5;
const darkenRate = 15;

export default {
	type: "light",
	action: {
		active: colors.blueGrey[600]
	},
	background: {
		default: colors.common.white,
		dark: athensGray,
		paper: colors.common.white,
		black: black
	},
	primary: {
		main: primary,
		text: white
	},
	secondary: {
		main: secondary,
		text: white
	},
	success: {
		main: success,
		text: white,
		dark: tinycolor(success).darken(darkenRate).toHexString(),
		light: tinycolor(success).lighten(lightenRate).toHexString()
	},
	danger: {
		main: danger,
		text: white,
		dark: tinycolor(danger).darken(darkenRate).toHexString(),
		light: tinycolor(danger).lighten(lightenRate).toHexString()
	},
	default: {
		main: white
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.blueGrey[600]
	},
	placeholder: {
		text: kashmirBlue
	}
};
