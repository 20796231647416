import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountReducer from "./accountReducer";
import notificationsReducer from "./notificationsReducer";

const rootReducer = combineReducers({
	account: accountReducer,
	notifications: notificationsReducer,
	form: formReducer
});

export default rootReducer;
