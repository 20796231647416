import { colors } from "@material-ui/core";

export default {
	root: {},
	input: {
		"&::placeholder": {
			opacity: 1,
			color: colors.blueGrey[600]
		}
	}
};
