import axios from 'axios';
import axiosInstance from 'src/utils/axios';
import { CLOUD_HOST } from 'src/config';

class AccountService {
  updateAdvertiserProfile = (category, values, emailValueChanged) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .patch('advertisers/me/', {
          advertiser_category: category,
          website: values.website,
          phone_number: values.phone === '+1' ? null : values.phone,
          ...(emailValueChanged === true && {
            user: {
              email: values.email
            }
          })
        })
        .then(response => {
          if (response.data.data) {
            resolve(response.data);
          } else {
            reject(response.data.data.message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  changeProfilePicture = file =>
    new Promise((resolve, reject) => {
      axiosInstance
        .get('upload_signature/')
        .then(response => {
          const cloudinaryInfo = response.data.data;
          const fileType = file.type;
          const resourceType = fileType.split('/')[0];
          const cloud = cloudinaryInfo.cloud_name;
          const timestamp = cloudinaryInfo.timestamp;
          const apiKey = cloudinaryInfo.api_key;
          const publicId = cloudinaryInfo.public_id;
          const signature = cloudinaryInfo.signature;
          const uploadUrl = `https://api.cloudinary.com/v1_1/${cloud}/${resourceType}/upload`;
          const formData = new FormData();
          formData.append('file', file);
          formData.append('signature', signature);
          formData.append('api_key', apiKey);
          formData.append('public_id', publicId);
          formData.append('timestamp', timestamp);

          return axios
            .post(uploadUrl, formData)
            .then(response => {
              const { public_id, format, bytes } = response.data;

              const payload = {
                ressource_name: public_id,
                host: CLOUD_HOST,
                extension: format,
                cloud_file_bytes: bytes
              };

              return axiosInstance
                .post('users/me/set_picture/', payload)
                .then(response => {
                  if (response.data.data) {
                    resolve(response.data.data);
                  }
                })
                .catch(error => {
                  reject(error);
                });
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
}

const accountService = new AccountService();

export default accountService;
