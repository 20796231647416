import authService from 'src/services/authService';
import accountService from 'src/services/accountService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE_SUCCESS = '@account/update-profile-success';
export const UPDATE_PROFILE_FAILURE = '@account/update-profile-failure';
export const UPDATE_PROFILE_PICTURE_SUCCESS =
  '@account/update-profile-picture-success';
export const UPDATE_PROFILE_PICTURE_FAILURE =
  '@account/update-profile-picture-failure';
export const LOGIN_REMEMBER_ME = '@account/login-remember-me';

export function login(email, password, stayLoggedIn) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(
        email,
        password,
        stayLoggedIn
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return async dispatch => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(category, values, emailValueChanged) {
  return async dispatch => {
    try {
      const account = await accountService.updateAdvertiserProfile(
        category,
        values,
        emailValueChanged
      );
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: account.data
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_FAILURE });
      throw error;
    }
  };
}

export function updateProfilePicture(file) {
  return async dispatch => {
    try {
      const account = await accountService.changeProfilePicture(file);
      dispatch({
        type: UPDATE_PROFILE_PICTURE_SUCCESS,
        payload: account.url
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_PICTURE_FAILURE });
      throw error;
    }
  };
}
