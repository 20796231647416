/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, Fragment } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  copyright: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    display: 'block'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  chip: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.text
  }
}));

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  const navConfig = [
    {
      items: [
        {
          title: 'Dashboard',
          icon: BarChartIcon,
          href: '/dashboard'
        },
        {
          title: 'Settings',
          icon: SettingsIcon,
          href: '/settings'
        }
      ]
    }
  ];

  const { user } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <Link component={RouterLink} to="/app/">
              <Logo />
            </Link>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/settings">
              {user.picture_url ? (
                <Avatar className={classes.avatar} src={user.picture_url} />
              ) : (
                <Avatar
                  alt="User"
                  className={classes.avatar}
                  src={'/static/images/brands/default.png'}
                />
              )}
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/settings"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.full_name ? user.full_name : null}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <div className={classes.copyright}>
          <Divider />
          <Box p={2}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textPrimary">
                &copy; {new Date().getFullYear()}{' '}
                <a
                  href="https://sda.media/"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  SDA Media
                </a>
                .
              </Typography>
              <Typography variant="h6" color="textPrimary">
                All Rights Reserved.
              </Typography>
            </Box>
          </Box>
        </div>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Fragment>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
