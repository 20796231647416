import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, makeStyles } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { logout } from 'src/actions/accountActions';

const useStyles = makeStyles(theme => ({
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <Fragment>
      <Button
        className={classes.logoutButton}
        color="inherit"
        onClick={handleLogout}
      >
        <InputIcon className={classes.logoutIcon} />
        Logout
      </Button>
    </Fragment>
  );
}

export default Account;
