import jwtDecode from 'jwt-decode';
import axiosInstance from 'src/utils/axios';

class AuthService {
  redirectToLoginPage = () => {
    window.location.href = '/login/';
  };

  setAxiosInterceptors = () => {
    // Check if user is logged in
    if (
      this.isUserLoggedIn() === null &&
      this.getAccessToken() &&
      this.getRefreshToken()
    ) {
      this.logout();
      this.redirectToLoginPage();
    }

    axiosInstance.interceptors.request.use(
      config => {
        const token = this.getAccessToken();
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `JWT ${token}`;
        }
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config;

        if (
          error.response.status === 401 &&
          originalRequest.url === `${originalRequest.baseURL}tokens/refresh/`
        ) {
          this.logout();
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          const refreshToken = this.getRefreshToken();
          if (refreshToken) {
            const validToken = this.isValidToken(refreshToken);
            if (validToken) {
              return axiosInstance
                .post('tokens/refresh/', { refresh: refreshToken })
                .then(response => {
                  this.setAccessTokenSession(response.data.access);
                  this.setRefreshTokenSession(response.data.refresh);

                  axiosInstance.defaults.headers.Authorization = `JWT ${response.data.access}`;
                  originalRequest.headers.Authorization = `JWT ${response.data.access}`;

                  return axiosInstance(originalRequest);
                })
                .catch(err => {
                  throw err;
                });
            }
            this.logout();
            this.redirectToLoginPage();
          } else {
            this.logout();
            this.redirectToLoginPage();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  loginWithEmailAndPassword = (email, password, stayLoggedIn) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .post('login', { email, password })
        .then(response => {
          if (response.data.data) {
            if (
              response.data.data &&
              response.data.data.user.role === 'GUST_USER' &&
              response.data.data.user.role_id === '0'
            ) {
              this.setAccessTokenSession(response.data.data.access);
              this.setRefreshTokenSession(response.data.data.refresh);
              this.keepUserLoggedIn(stayLoggedIn);
              resolve(response.data.data.user);
            } else {
              reject('Something went wrong');
            }
          } else {
            reject(response.data.data.message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axiosInstance
        .get('users/me')
        .then(response => {
          const user = response.data.data;
          if (user) {
            resolve(user);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          this.logout();
          this.redirectToLoginPage();
        });
    });

  logout = () => {
    this.setAccessTokenSession(null);
    this.setRefreshTokenSession(null);
    this.forgetUser();
  };

  keepUserLoggedIn = state => {
    if (state) {
      localStorage.setItem('isLoggedIn', state);
    } else {
      sessionStorage.setItem('isLoggedIn', state);
    }
  };

  setAccessTokenSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axiosInstance.defaults.headers.common.Authorization = `JWT ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  setRefreshTokenSession = refreshToken => {
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
      axiosInstance.defaults.headers.common.Authorization = `JWT ${refreshToken}`;
    } else {
      localStorage.removeItem('refreshToken');
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  getRefreshToken = () => localStorage.getItem('refreshToken');

  isUserLoggedIn = () =>
    sessionStorage.getItem('isLoggedIn')
      ? sessionStorage.getItem('isLoggedIn')
      : localStorage.getItem('isLoggedIn');

  forgetUser = () =>
    sessionStorage.getItem('isLoggedIn')
      ? sessionStorage.removeItem('isLoggedIn')
      : localStorage.removeItem('isLoggedIn');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken() && !!this.getRefreshToken();
}

const authService = new AuthService();

export default authService;
