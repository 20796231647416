/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { createMuiTheme } from "@material-ui/core";
import { softShadows } from "./shadows";
import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";

const theme = createMuiTheme({
	palette,
	typography,
	overrides,
	shadows: softShadows
});
export default theme;
