import { colors } from "@material-ui/core";

export default {
	root: {
		backgroundColor: "rgba(0,0,0,0.075)"
	},
	deletable: {
		"&:focus": {
			backgroundColor: colors.blueGrey[100]
		}
	}
};
